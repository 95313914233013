import React from "react";
import "../css/PortfolioSection.css";

const PortfolioSection = () => {
    return (
        <section id="about" className="portfolio-container">
            {/* Right Side */}
            <div className="portfolio-right">
                <div className="logo-container">
                    <img
                        src="/assets/img/logo/logo.png"
                        alt="Logo"
                        className="logo1"
                    />
                </div>
                <h1 className="title">
                    Explore <span className="highlight">XYZ Team,</span><br></br> where{" "}
                    <span className="highlight-orange">Quality meets</span> {" "}
                    <span className="highlight-orange">Creativity</span>
                </h1>
                <p className="text">
                <span className="highlight">XYZ </span> is a newly established interior design studio specializing in
                    creating unique and personalized living and working spaces.
                </p>
                <button className="explore-btn">Explore XYZ</button>
            </div>

            {/* Left Side */}
            <div className="portfolio-left">
                <div className="portfolio-left-image">
                    <img
                        src="/assets/img/Horizontal image/Untitled-3.webp"
                        alt="Main"
                        className="main-image"
                    />
                </div>
                <div className="portfolio-left-stacked">
                    <img
                        src="/assets/img/Horizontal image/source (2) copy.webp"
                        alt="Stacked 1"
                        className="stacked-image"
                    />
                    <img
                        src="/assets/img/vertical images/source copy.webp"
                        alt="Stacked 2"
                        className="stacked-image"
                    />
                </div>
            </div>
        </section>
    );
};

export default PortfolioSection;
