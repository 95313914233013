import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import "../css/HeroSection.css";

const HeroSection = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const images = [
        { url: `${process.env.PUBLIC_URL}/assets/img/Fullscreengallery/events.webp`, link: "/portfolio" },
        { url: `${process.env.PUBLIC_URL}/assets/img/Fullscreengallery/events.webp`, link: "/services" },
        { url: `${process.env.PUBLIC_URL}/assets/img/Fullscreengallery/events.webp`, link: "/contact" },
    ];

    const startX = useRef(null);

    // Wrap handleNext with useCallback for stable reference
    const handleNext = useCallback(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, [images.length]);

    const handlePrev = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleDragStart = (e) => {
        startX.current = e.clientX || e.touches[0].clientX;
    };

    const handleDragEnd = (e) => {
        const endX = e.clientX || e.changedTouches[0].clientX;
        const diffX = endX - startX.current;
        if (diffX > 50) handlePrev();
        else if (diffX < -50) handleNext();
    };

    // Automatically change the image every 2 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 2000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [handleNext]); // Include handleNext as a dependency

    return (
        <section id="portfolio" className="hero-section">
            <div className="hero-text">
                <h2>Explore Our</h2>
                <h1>Portfolio</h1>
            </div>
            <div
                className="hero-carousel"
                onTouchStart={handleDragStart}
                onTouchEnd={handleDragEnd}
                onMouseDown={handleDragStart}
                onMouseUp={handleDragEnd}
            >
                <button className="carousel-btn left" onClick={handlePrev}>
                    &#8249;
                </button>
                <div className="carousel-images">
                    {images.map((image, index) => (
                        <Link
                            key={index}
                            to={image.link}
                            className={`carousel-image ${
                                index === activeIndex
                                    ? "active"
                                    : index === (activeIndex - 1 + images.length) % images.length
                                    ? "prev"
                                    : index === (activeIndex + 1) % images.length
                                    ? "next"
                                    : "hidden"
                            }`}
                            style={{
                                backgroundImage: `url(${image.url})`,
                            }}
                        ></Link>
                    ))}
                </div>
                <button className="carousel-btn right" onClick={handleNext}>
                    &#8250;
                </button>
            </div>
        </section>
    );
};

export default HeroSection;
