import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../css/ContactSection.css";

const ContactSection = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
    });

    const [statusMessage, setStatusMessage] = useState(null);
    const [messageColor, setMessageColor] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const clearStatusMessage = () => {
        setTimeout(() => {
            setStatusMessage(null);
        }, 5000);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            message: formData.message,
        };

        emailjs
            .send(
                "service_rrfomca",
                "template_791gizr",
                templateParams,
                "Ahz8qZX72V5v0pM7p"
            )
            .then(
                (response) => {
                    setStatusMessage("Email sent successfully!");
                    setMessageColor("green");
                    setFormData({ firstName: "", lastName: "", email: "", message: "" });
                    clearStatusMessage();
                },
                (error) => {
                    setStatusMessage("Failed to send email. Please try again.");
                    setMessageColor("red");
                    clearStatusMessage();
                }
            );
    };

    return (
        <section id="contact" className="contact-section">
            <div className="contact-container">
                <div className="contact-form">
                    <h2>Contact Us</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    id="firstName"
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">E-mail Address</label>
                            <input
                                id="email"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Your Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>
                        <button type="submit">Send</button>
                        {statusMessage && (
                            <p
                                className="status-message"
                                style={{ color: messageColor }}
                            >
                                {statusMessage}
                            </p>
                        )}
                    </form>
                </div>
              
            </div>
        </section>
    );
};

export default ContactSection;
