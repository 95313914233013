import React from "react";
import "../css/About.css";

function About() {
  return (
    <div className="about-page">
      {/* Header Section */}
      <header className="about-header">
  <h1>ABOUT</h1>
  <div className="breadcrumbs">
    <a href="/">Home</a> / About
  </div>
</header>



      {/* Main Content Section */}
      <main className="about-content">
        {/* Background Image */}
        <div
          className="about-image"
          style={{
            backgroundImage: `url("/assets/img/Fullscreengallery/Exterior Design.webp")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            flex: 1,
          }}
        ></div>

        {/* Text Content */}
        <div className="about-text">
          <h2>About XYZ Team</h2>
          <p>
            We are an architectural and interior design firm based in the UAE
            and operating worldwide. We have delivered a number of creative
            interiors for hospitality, healthcare, office, retail, and
            residential spaces, from design concept to handover.
          </p>
          <p>
            We are a multicultural team of passionate individuals dedicated to
            delivering a unique project. This is always achieved through a
            tailored approach, reflecting the client’s needs and aspirations.
          </p>
          <p>
            We work hand in hand with a network of trusted worldwide partners
            in order to accommodate the most varied and complex projects. Our
            interiors are focused on flexibility and functionality without
            compromising on style and design.
          </p>
          <p>
            Our approach is to create the most suitable designs with assorted
            architectural features and subtle touches and refinement; a vision
            of elegance expressed with sensibility and care in every detail.
          </p>
        </div>
      </main>

      {/* Call to Action Section */}
      <div className="call-to-action">
  <a href="#" className="talk-with-us-button">
    Talk With Us
   
  </a>
</div>

    </div>
  );
}

export default About;
