import React from 'react'
import HeroSection from "../components/HeroSection";
import PortfolioSection from "../components/PortfolioSection";

import FullscreenGallery from "../components/FullscreenGallery";

import ContactSection from '../components/ContactSection';
function Home() {
  
  return (
   
      
    <>
    <FullscreenGallery />
    <PortfolioSection />
    <HeroSection />
    <ContactSection/>
    
</>
            
        
    
  

  
  )
}

export default Home