import React, { useState } from "react";
import "../css/FullscreenGallery.css";

const FullscreenGallery = () => {
  const images = [
    "/assets/img/Fullscreengallery/events.webp",
    "/assets/img/Fullscreengallery/Exhibitions.webp",
    "/assets/img/Fullscreengallery/Exterior Design.webp",
    "/assets/img/Fullscreengallery/Interior Design.webp",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="fullscreen-gallery">
      <div
        className="gallery-container"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={process.env.PUBLIC_URL + image}
            alt={`Slide ${index + 1}`}
            className="gallery-image"
          />
        ))}
      </div>

      {/* Left Arrow */}
      <button className="arrow left-arrow" onClick={handlePrev}>
        &#9664;
      </button>

      {/* Right Arrow */}
      <button className="arrow right-arrow" onClick={handleNext}>
        &#9654;
      </button>

      {/* Navigation Dots */}
      <div className="dots-container">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default FullscreenGallery;
