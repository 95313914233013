import React from "react";


import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Services from "./pages/Services";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
// import Three from "./components/Three";

function App() {
  return (
    <div>
     
      {/* <Three/> */}
      <BrowserRouter>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
   
         
        </Routes>
        <Footer />
      </BrowserRouter>
 
  
    </div>
  );
}

export default App;