import React from 'react';
import '../css/Services.css';

const servicesData = [
  {
    title: 'Residential Design & Build',
    description: 'We deliver personalized interior design and build services for residential projects across UAE and the region; complete refurbishments, extensions, existing space conversions, and top-notch renovations.',
    image: '/assets/img/Fullscreengallery/Exhibitions.webp',
  },
  {
    title: 'Corporate Design & Build',
    description: 'We create a space that represents the DNA of your brand – each detail is crafted keeping the brand’s personality in mind so that it helps clients understand your business, captures your brand values, and connects your team.',
    image: '/assets/img/Fullscreengallery/Exhibitions.webp',
  },
  {
    title: 'Hospitality',
    description: 'We provide extraordinary hospitality design and build, fueled by the power of creative solutions and harnessing the potential of out-of-the-box ideas to help brands achieve their objectives.',
    image: '/assets/img/Fullscreengallery/Exhibitions.webp',
  },
  {
    title: 'F&B',
    description: 'A good-looking restaurant layout is key to attracting customers and keeping them coming back. Understanding this fact, we bring together a number of elements that ensure a successful project.',
    image: '/assets/img/Fullscreengallery/Exhibitions.webp',
  },
  {
    title: 'Bespoke Furniture Design',
    description: 'We design and customize furniture that blends with your brand, using our aesthetic sense and creativity in response to the taste of usage of that space.',
    image: '/assets/img/Fullscreengallery/Exhibitions.webp',
  },
  {
    title: 'Retail',
    description: 'We not only design and build retail experiences but also a destination that influences brand loyalty and purchasing behavior by engaging interior store design combined with all needed elements.',
    image: '/assets/img/Fullscreengallery/Exhibitions.webp',
  },
  {
    title: 'Landscaping',
    description: 'Because your home is your ultimate sanctuary, we create award-winning landscape designs that will give it an instant appeal and value.',
    image: '/assets/img/Fullscreengallery/Exhibitions.webp',
  },
  {
    title: 'Swimming Pool',
    description: 'Poolside is the best side, and many memories made at the pool last a lifetime. Allow us to customize an outdoor living experience for taste, mood, and unforgettable moments.',
    image: '/assets/img/Fullscreengallery/Exhibitions.webp',
  },
];

const Services = () => {
  return (
    <div className="services-container">
      <h1 className="services-title">Our Services</h1>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-item">
            <div className="service-image" style={{ backgroundImage: `url(${service.image})` }}></div>
            <div className="service-content">
              <h2>{service.title}</h2>
              <p>{service.description}</p>
              <button className="read-more">Read More</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
