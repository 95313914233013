import React from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "../css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h2 className="footer-logo">XYZ Team</h2>
          <p className="footer-description">
            We are an architectural and interior design firm based in the UAE
            and operating worldwide. We have delivered a number of creative
            interiors for hospitality, healthcare, office, retail, and
            residential spaces, from design concept to handover.
          </p>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-section">
          <h3 className="footer-title">QUICK LINKS</h3>
          <ul className="footer-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/services">Our Services</Link></li>
            <li><Link to="/projects">Our Projects</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/contact">GET IN TOUCH</Link></li>
          </ul>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-section">
          <h3 className="footer-title">CONTACT US</h3>
          <address className="footer-address">
            <p>INFYA Exhibition Stand Fitting and Execution LLC</p>
            <p>Warehouse Number 4, 5, 6</p>
            <p>Dubai Investment Park First</p>
            <p>P.O. Box 390077 – Dubai, UAE</p>
            <p><strong>Mobile:</strong> +971 4 880 8890</p>
            <p><strong>Phone:</strong> +971 50 657 0757</p>
            <p><strong>Email:</strong> <a href="mailto:info@infya.com">info@infya.com</a></p>
          </address>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-bottom-left">
          &copy; Copyright 2024 - XYZ Team
        </p>
        <div className="footer-bottom-right">
          <a href="#facebook" className="social-icon">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#linkedin" className="social-icon">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a href="#instagram" className="social-icon">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
