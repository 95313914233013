import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "../css/Header.css";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`header ${menuOpen ? "menu-open" : ""}`}>
      <div className="header-container">
        {/* Logo */}
        <div className="logo">
          <Link to="/"> {/* Use Link instead of <a> */}
            <img
              src="assets/img/logo/logo.png"
              alt="Logo"
              className="logo-image"
            />
          </Link>
        </div>

        {/* Hamburger Menu */}
        <div className="hamburger" onClick={toggleMenu}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>

        {/* Navbar */}
        <nav className={`navbar ${menuOpen ? "active" : ""}`}>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/services">Our Services</Link></li>
            <li><Link to="/projects">Our Projects</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li className="get-in-touch">
              <Link to="/contact">Get in Touch</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
